import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "width70" }
const _hoisted_3 = { class: "border-radius-10" }
const _hoisted_4 = { class: "close-link-container" }
const _hoisted_5 = { class: "popup-height" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "inline-flex100" }
const _hoisted_8 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_9 = {
  key: 1,
  class: "icon-container"
}
const _hoisted_10 = {
  key: 2,
  class: "loading-container"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "text-container" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "notification-exception"
}
const _hoisted_15 = { class: "time-icon-container" }
const _hoisted_16 = { class: "time" }
const _hoisted_17 = {
  key: 1,
  class: "padding-8"
}
const _hoisted_18 = { class: "seeAll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showNotificationPopup)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.mouseOver())),
          onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.mouseLeave()))
        }, [
          _createElementVNode("div", {
            id: "notifications",
            class: _normalizeClass([_ctx.GetPopupCSSClass(), "border-radius-10"])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("Notifications.Notifications")), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("a", {
                  href: "javascript:void(0)",
                  "aria-hidden": "true",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.ShowNotificationPopup(false);})
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("i", {
                    class: "fa fa-times fa-times-color",
                    "aria-hidden": "true"
                  }, null, -1)
                ]))
              ])
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.localNotifications.length>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localNotifications, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.GUID
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          (item.IsSuccess && item.NotificationStatus== _ctx.notificationReceived)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
                                _createElementVNode("i", { class: "fa fa-check-circle-o check-icon" }, null, -1)
                              ])))
                            : (item.IsSuccess === false && item.NotificationStatus== _ctx.notificationReceived)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[6] || (_cache[6] = [
                                  _createElementVNode("i", { class: "fa fa-times-circle-o times-icon" }, null, -1)
                                ])))
                              : (item.IsSuccess === false && item.NotificationStatus== _ctx.notificationSending)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createElementVNode("img", {
                                      src: _ctx.loadingImage,
                                      class: "loading-img"
                                    }, null, 8, _hoisted_11)
                                  ]))
                                : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", null, [
                              _createElementVNode("label", {
                                class: "notification-description",
                                innerHTML: _ctx.$t(item.Description)
                              }, null, 8, _hoisted_13),
                              (item.ErrorMessage!== null && item.ErrorMessage!== '')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(item.ErrorMessage), 1))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(item.RelativeResultDateTime), 1)
                          ])
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1))
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t("Notifications.NoNotifications")), 1)
                  ]))
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ClearAllNotifications()))
              }, _toDisplayString(_ctx.$t('ClearAll')), 1)
            ])
          ], 2)
        ], 32))
      : _createCommentVNode("", true)
  ]))
}