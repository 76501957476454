import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_4 = { class: "modal-dialog width90" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = {
  id: "myTabs",
  class: "nav nav-tabs width95",
  role: "tablist"
}
const _hoisted_8 = {
  href: "#LicensingContent",
  "data-toggle": "tab"
}
const _hoisted_9 = {
  href: "#ConsumptionBasedProducts",
  "data-toggle": "tab"
}
const _hoisted_10 = { class: "tab-content modal-body tabLicense" }
const _hoisted_11 = {
  id: "LicensingContent",
  class: "tab-pane active"
}
const _hoisted_12 = { class: "inline-flex100" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "col-md-12 application-container" }
const _hoisted_16 = { class: "col-md-6 padding-0" }
const _hoisted_17 = {
  id: "mytable",
  class: "table-border"
}
const _hoisted_18 = { class: "col-md-4" }
const _hoisted_19 = { for: "input-name" }
const _hoisted_20 = { class: "col-md-8" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "col-md-4" }
const _hoisted_23 = { for: "input-name" }
const _hoisted_24 = { class: "col-md-8" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "col-md-4" }
const _hoisted_27 = {
  for: "input-name",
  class: "visible-lg visible-md"
}
const _hoisted_28 = {
  class: "hidden-lg hidden-md",
  for: "input-name"
}
const _hoisted_29 = { class: "col-md-8" }
const _hoisted_30 = { class: "col-md-4" }
const _hoisted_31 = {
  class: "visible-lg visible-md",
  for: "input-name"
}
const _hoisted_32 = {
  class: "hidden-lg hidden-md",
  for: "input-name"
}
const _hoisted_33 = { class: "col-md-8" }
const _hoisted_34 = { class: "col-md-6" }
const _hoisted_35 = {
  id: "mytable",
  class: "col-md-12 table-border"
}
const _hoisted_36 = { colspan: "2" }
const _hoisted_37 = { class: "col-xs-12" }
const _hoisted_38 = { for: "input-name" }
const _hoisted_39 = { class: "margin-left-7" }
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { colspan: "2" }
const _hoisted_42 = { class: "col-xs-12" }
const _hoisted_43 = { for: "input-name" }
const _hoisted_44 = { class: "inline-flex" }
const _hoisted_45 = { class: "margin-left-11" }
const _hoisted_46 = { key: 1 }
const _hoisted_47 = { colspan: "2" }
const _hoisted_48 = { class: "col-md-12" }
const _hoisted_49 = { for: "input-name" }
const _hoisted_50 = { class: "margin-left-7" }
const _hoisted_51 = { colspan: "2" }
const _hoisted_52 = { class: "col-md-12" }
const _hoisted_53 = {
  for: "input-name",
  class: "hidden-xs hidden-sm"
}
const _hoisted_54 = {
  for: "input-name",
  class: "hidden-lg hidden-md"
}
const _hoisted_55 = {
  key: 0,
  class: "margin-left-11"
}
const _hoisted_56 = {
  key: 1,
  class: "margin-left-11"
}
const _hoisted_57 = { key: 2 }
const _hoisted_58 = { colspan: "2" }
const _hoisted_59 = { class: "col-md-12" }
const _hoisted_60 = { for: "input-name" }
const _hoisted_61 = { class: "margin-left-7" }
const _hoisted_62 = { key: 3 }
const _hoisted_63 = { colspan: "2" }
const _hoisted_64 = { class: "col-md-12" }
const _hoisted_65 = { for: "input-name" }
const _hoisted_66 = { class: "margin-left-7" }
const _hoisted_67 = { class: "details-container" }
const _hoisted_68 = { class: "currentSubscription" }
const _hoisted_69 = { class: "details-table" }
const _hoisted_70 = {
  id: "licenseItemTable",
  class: "license-item-table"
}
const _hoisted_71 = { class: "row-background" }
const _hoisted_72 = {
  colspan: "10",
  class: "font-weight-700"
}
const _hoisted_73 = { key: 0 }
const _hoisted_74 = { class: "license-row-background" }
const _hoisted_75 = { class: "clsPeddingLeft5px font-size-13" }
const _hoisted_76 = { class: "table-header" }
const _hoisted_77 = { class: "table-header" }
const _hoisted_78 = { class: "table-header" }
const _hoisted_79 = { class: "table-header" }
const _hoisted_80 = {
  key: 0,
  class: "clsPeddingLeft5px width200"
}
const _hoisted_81 = {
  key: 1,
  class: "clsPeddingLeft5px width200"
}
const _hoisted_82 = ["innerHTML"]
const _hoisted_83 = {
  key: 0,
  class: "display-inline"
}
const _hoisted_84 = { class: "clsPeddingLeft5px AdminsLimit" }
const _hoisted_85 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_86 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_87 = { class: "clsPeddingLeft5px renewal" }
const _hoisted_88 = { key: 0 }
const _hoisted_89 = { key: 1 }
const _hoisted_90 = { key: 1 }
const _hoisted_91 = { class: "row-background" }
const _hoisted_92 = {
  colspan: "10",
  class: "Future-Packages"
}
const _hoisted_93 = { class: "license-row-background" }
const _hoisted_94 = { class: "clsPeddingLeft5px font-size-13" }
const _hoisted_95 = { class: "table-header" }
const _hoisted_96 = { class: "table-header" }
const _hoisted_97 = { class: "table-header" }
const _hoisted_98 = { class: "table-header" }
const _hoisted_99 = {
  key: 0,
  class: "clsPeddingLeft5px width200"
}
const _hoisted_100 = {
  key: 1,
  class: "clsPeddingLeft5px width200"
}
const _hoisted_101 = ["innerHTML"]
const _hoisted_102 = {
  key: 0,
  class: "display-inline"
}
const _hoisted_103 = { class: "clsPeddingLeft5px AdminsLimit" }
const _hoisted_104 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_105 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_106 = { class: "clsPeddingLeft5px renewal" }
const _hoisted_107 = { key: 0 }
const _hoisted_108 = { key: 1 }
const _hoisted_109 = { key: 2 }
const _hoisted_110 = { class: "margin-bottom-25" }
const _hoisted_111 = {
  id: "tableAddOn",
  class: "width98"
}
const _hoisted_112 = { class: "row-background" }
const _hoisted_113 = {
  colspan: "6",
  class: "font-weight-700"
}
const _hoisted_114 = { key: 0 }
const _hoisted_115 = { class: "license-row-background" }
const _hoisted_116 = { class: "clsPeddingLeft5px font-size-13" }
const _hoisted_117 = { class: "table-header" }
const _hoisted_118 = { class: "table-header" }
const _hoisted_119 = {
  key: 0,
  class: "clsPeddingLeft5px width66"
}
const _hoisted_120 = { class: "clsPeddingLeft5px width66" }
const _hoisted_121 = ["innerHTML"]
const _hoisted_122 = {
  key: 0,
  class: "count"
}
const _hoisted_123 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_124 = { class: "clsPeddingLeft5px renewal" }
const _hoisted_125 = { key: 0 }
const _hoisted_126 = { key: 1 }
const _hoisted_127 = { key: 0 }
const _hoisted_128 = { key: 1 }
const _hoisted_129 = { key: 1 }
const _hoisted_130 = { class: "margin-bottom-25" }
const _hoisted_131 = {
  id: "tablePremiumFeature",
  class: "width98"
}
const _hoisted_132 = { class: "row-background" }
const _hoisted_133 = {
  colspan: "8",
  class: "font-weight-700"
}
const _hoisted_134 = { key: 0 }
const _hoisted_135 = { class: "license-row-background" }
const _hoisted_136 = { class: "clsPeddingLeft5px font-size-13" }
const _hoisted_137 = { class: "table-header" }
const _hoisted_138 = { class: "table-header" }
const _hoisted_139 = { class: "clsPeddingLeft5px width66" }
const _hoisted_140 = ["innerHTML"]
const _hoisted_141 = { class: "clsPeddingLeft5px table-data" }
const _hoisted_142 = { class: "clsPeddingLeft5px renewal" }
const _hoisted_143 = { key: 0 }
const _hoisted_144 = { key: 1 }
const _hoisted_145 = { key: 0 }
const _hoisted_146 = { key: 1 }
const _hoisted_147 = { key: 1 }
const _hoisted_148 = {
  id: "ConsumptionBasedProducts",
  class: "tab-pane"
}
const _hoisted_149 = { class: "row date-selector-div" }
const _hoisted_150 = { class: "col-sm-3 padding-0" }
const _hoisted_151 = { for: "input-LogoUri" }
const _hoisted_152 = ["value"]
const _hoisted_153 = { class: "col-sm-2" }
const _hoisted_154 = {
  key: 0,
  class: "ajax-loader"
}
const _hoisted_155 = ["src"]
const _hoisted_156 = { key: 0 }
const _hoisted_157 = { class: "margin-top-10" }
const _hoisted_158 = { key: 1 }
const _hoisted_159 = {
  id: "tableConsumptionBillingLog",
  class: "consumption-table table-bordered"
}
const _hoisted_160 = { class: "consumption-table-header" }
const _hoisted_161 = { class: "clsPeddingLeft5px" }
const _hoisted_162 = { class: "text-center" }
const _hoisted_163 = { class: "text-center" }
const _hoisted_164 = { class: "text-center" }
const _hoisted_165 = { class: "table-data text-left" }
const _hoisted_166 = { class: "table-data text-center" }
const _hoisted_167 = { class: "table-data text-center" }
const _hoisted_168 = { class: "text-center width20" }
const _hoisted_169 = { style: {"background":"#ddd"} }
const _hoisted_170 = { class: "font-weight-700 clsPeddingLeft5px width200" }
const _hoisted_171 = { class: "font-weight-700 text-center" }
const _hoisted_172 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.localBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localShowModalPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, { name: "modal" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "close",
                        "data-dismiss": "modal",
                        "aria-label": "Close",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
                      }, _cache[7] || (_cache[7] = [
                        _createElementVNode("span", { "aria-hidden": "true" }, "×", -1)
                      ])),
                      _createElementVNode("ul", _hoisted_7, [
                        _createElementVNode("li", {
                          id: "liLicensingContent",
                          class: _normalizeClass(["nav-item", {'active': _ctx.isLicenceDetailsActice}]),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetActiveTab(true)))
                        }, [
                          _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('YourLicense')), 1)
                        ], 2),
                        (_ctx.isMeetAppLiveEnabled)
                          ? (_openBlock(), _createElementBlock("li", {
                              key: 0,
                              id: "liConsumptionBasedProducts",
                              class: _normalizeClass(["nav-item", {'active': !_ctx.isLicenceDetailsActice}]),
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetActiveTab(false)))
                            }, [
                              _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t('ConsumptionBasedProducts')), 1)
                            ], 2))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("img", {
                              src: _ctx.applicationIconUrl,
                              class: "image-icon"
                            }, null, 8, _hoisted_14),
                            _cache[8] || (_cache[8] = _createTextVNode("   "))
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("table", _hoisted_17, [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_18, [
                                    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('Application.Name')) + ":", 1)
                                  ]),
                                  _createElementVNode("td", _hoisted_20, [
                                    _createElementVNode("span", { innerHTML: _ctx.applicationName }, null, 8, _hoisted_21)
                                  ])
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_22, [
                                    _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('Application.Package')) + ":", 1)
                                  ]),
                                  _createElementVNode("td", _hoisted_24, [
                                    _createElementVNode("span", {
                                      innerHTML: _ctx.applicationLicenseItems[0].Product.Name
                                    }, null, 8, _hoisted_25)
                                  ])
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_26, [
                                    _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('Registration.Type')) + ":", 1),
                                    _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('Registration')) + ":", 1)
                                  ]),
                                  _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.applicationCreateAccountType), 1)
                                ]),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_30, [
                                    _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('NumberOfAdminUsers')) + ":", 1),
                                    _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('NumberOfAdmin')) + ":", 1)
                                  ]),
                                  _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.applicationConsumedAdmins) + "/" + _toDisplayString(_ctx.applicationActiveLicense.Maxadmins), 1)
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("table", _hoisted_35, [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_36, [
                                    _createElementVNode("div", _hoisted_37, [
                                      _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('Startdate')) + ":", 1),
                                      _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.formatDate(_ctx.applicationActiveLicense.Startdate)), 1)
                                    ])
                                  ])
                                ]),
                                (!_ctx.isApplicationLegacyModel)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_40, [
                                      _createElementVNode("td", _hoisted_41, [
                                        _createElementVNode("div", _hoisted_42, [
                                          _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t('Renewal.Date')) + ":", 1),
                                          _createElementVNode("div", _hoisted_44, [
                                            _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.formatDate(_ctx.applicationActiveLicense.Renewaldate) + " (" + _ctx.applicationActiveLicense.LeftDays + ")"), 1)
                                          ])
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                (!_ctx.isApplicationOnNewPackage)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_46, [
                                      _createElementVNode("td", _hoisted_47, [
                                        _createElementVNode("div", _hoisted_48, [
                                          _createElementVNode("label", _hoisted_49, _toDisplayString(_ctx.$t('Event.Limit')) + ":", 1),
                                          _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.applicationConsumedEvents) + "/" + _toDisplayString(_ctx.applicationActiveLicense.Maxevents), 1)
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("tr", null, [
                                  _createElementVNode("td", _hoisted_51, [
                                    _createElementVNode("div", _hoisted_52, [
                                      _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t('MaxParticipantsPerEvent')) + ":", 1),
                                      _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('ParticipantsPerEvent')) + ":", 1),
                                      (_ctx.isApplicationLegacyModel)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_55, _toDisplayString(_ctx.$t('Nolimit')), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString(_ctx.applicationLicenseItems[0].MaxParticipantsPerEvent), 1))
                                    ])
                                  ])
                                ]),
                                (_ctx.isApplicationOnNewPackage)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_57, [
                                      _createElementVNode("td", _hoisted_58, [
                                        _createElementVNode("div", _hoisted_59, [
                                          _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t('ParticipantSeats')) + ":", 1),
                                          _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.applicationActiveLicense.Maxparticipants), 1)
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.additionalParticipantSeatsCount !== 0)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_62, [
                                      _createElementVNode("td", _hoisted_63, [
                                        _createElementVNode("div", _hoisted_64, [
                                          _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t('AdditionalParticipantSeats')) + ":", 1),
                                          _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.additionalParticipantSeatsCount), 1)
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_67, [
                            _createElementVNode("div", _hoisted_68, _toDisplayString(_ctx.$t('Details')), 1),
                            _createElementVNode("div", _hoisted_69, [
                              _createElementVNode("table", _hoisted_70, [
                                _createElementVNode("tr", _hoisted_71, [
                                  _createElementVNode("td", _hoisted_72, _toDisplayString(_ctx.$t('Package')), 1)
                                ]),
                                (_ctx.CheckPreviousLicenseItems())
                                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_73, [
                                      _createElementVNode("tr", _hoisted_74, [
                                        _createElementVNode("th", _hoisted_75, _toDisplayString(_ctx.$t('Name')), 1),
                                        _createElementVNode("th", _hoisted_76, _toDisplayString(_ctx.$t('IncludeAdmins')), 1),
                                        _createElementVNode("th", _hoisted_77, _toDisplayString(_ctx.$t('Startdate')), 1),
                                        _createElementVNode("th", _hoisted_78, _toDisplayString(_ctx.$t('EndDate')), 1),
                                        _createElementVNode("th", _hoisted_79, _toDisplayString(_ctx.$t('Renewable')), 1)
                                      ]),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicationLicenseItemsThisYear, (licenseItem) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          key: licenseItem.Id
                                        }, [
                                          (licenseItem.Product.Name == _ctx.legacyPackageName)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_80, _toDisplayString(_ctx.$t('ParallelEvents')), 1))
                                            : (_openBlock(), _createElementBlock("td", _hoisted_81, [
                                                _createElementVNode("span", {
                                                  innerHTML: licenseItem.Product.Name
                                                }, null, 8, _hoisted_82),
                                                (licenseItem.IsCustom)
                                                  ? (_openBlock(), _createElementBlock("p", _hoisted_83, " (" + _toDisplayString(_ctx.$t('custom')) + ") ", 1))
                                                  : _createCommentVNode("", true)
                                              ])),
                                          _createElementVNode("td", _hoisted_84, _toDisplayString(licenseItem.AdminsLimit), 1),
                                          _createElementVNode("td", _hoisted_85, _toDisplayString(licenseItem.FormattedAddedDate), 1),
                                          _createElementVNode("td", _hoisted_86, _toDisplayString(licenseItem.FormattedEndDate), 1),
                                          _createElementVNode("td", _hoisted_87, [
                                            (licenseItem.IsRenewable)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_88, _toDisplayString(_ctx.$t('Yes')), 1))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_89, _toDisplayString(_ctx.$t('No')), 1))
                                          ])
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.CheckNextLicenseItem())
                                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_90, [
                                      _createElementVNode("tr", _hoisted_91, [
                                        _createElementVNode("td", _hoisted_92, _toDisplayString(_ctx.$t('FuturePackages')), 1)
                                      ]),
                                      _createElementVNode("tr", _hoisted_93, [
                                        _createElementVNode("th", _hoisted_94, _toDisplayString(_ctx.$t('Name')), 1),
                                        _createElementVNode("th", _hoisted_95, _toDisplayString(_ctx.$t('IncludeAdmins')), 1),
                                        _createElementVNode("th", _hoisted_96, _toDisplayString(_ctx.$t('Startdate')), 1),
                                        _createElementVNode("th", _hoisted_97, _toDisplayString(_ctx.$t('EndDate')), 1),
                                        _createElementVNode("th", _hoisted_98, _toDisplayString(_ctx.$t('Renewable')), 1)
                                      ]),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicationLicenseItemsnextYear, (licenseItem) => {
                                        return (_openBlock(), _createElementBlock("tr", {
                                          key: licenseItem.Id
                                        }, [
                                          (licenseItem.Product.Name == _ctx.legacyPackageName)
                                            ? (_openBlock(), _createElementBlock("td", _hoisted_99, _toDisplayString(_ctx.$t('ParallelEvents')), 1))
                                            : (_openBlock(), _createElementBlock("td", _hoisted_100, [
                                                _createElementVNode("span", {
                                                  innerHTML: licenseItem.Product.Name
                                                }, null, 8, _hoisted_101),
                                                (licenseItem.IsCustom)
                                                  ? (_openBlock(), _createElementBlock("p", _hoisted_102, " (" + _toDisplayString(_ctx.$t('custom')) + ") ", 1))
                                                  : _createCommentVNode("", true)
                                              ])),
                                          _createElementVNode("td", _hoisted_103, _toDisplayString(licenseItem.AdminsLimit), 1),
                                          _createElementVNode("td", _hoisted_104, _toDisplayString(licenseItem.FormattedAddedDate), 1),
                                          _createElementVNode("td", _hoisted_105, _toDisplayString(licenseItem.FormattedEndDate), 1),
                                          _createElementVNode("td", _hoisted_106, [
                                            (licenseItem.IsRenewable)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_107, _toDisplayString(_ctx.$t('Yes')), 1))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_108, _toDisplayString(_ctx.$t('No')), 1))
                                          ])
                                        ]))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.applicationLicenseItems == null|| _ctx.applicationLicenseItems.length== 0)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_109, [
                                      _createElementVNode("td", null, _toDisplayString(_ctx.$t('NoPackageAdded')), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_110, [
                                _createElementVNode("table", _hoisted_111, [
                                  _createElementVNode("tr", _hoisted_112, [
                                    _createElementVNode("td", _hoisted_113, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Addons')) + " (", 1),
                                      _createElementVNode("b", null, _toDisplayString(_ctx.GetAddOnCounts()), 1),
                                      _cache[9] || (_cache[9] = _createTextVNode(") "))
                                    ])
                                  ]),
                                  (_ctx.applicationAddOns.length>0)
                                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_114, [
                                        _createElementVNode("tr", _hoisted_115, [
                                          _createElementVNode("th", _hoisted_116, _toDisplayString(_ctx.$t('Name')), 1),
                                          _createElementVNode("th", _hoisted_117, _toDisplayString(_ctx.$t('AddedDate')), 1),
                                          _createElementVNode("th", _hoisted_118, _toDisplayString(_ctx.$t('Renewable')), 1)
                                        ]),
                                        (_ctx.groupedFeaturesListAddOns.length>0)
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupedFeaturesListAddOns, (licenseItem) => {
                                              return (_openBlock(), _createElementBlock("tr", {
                                                key: licenseItem.Id
                                              }, [
                                                ((!licenseItem[0].Product.IsRecurring) && (licenseItem[0].Product.Duration == 0))
                                                  ? (_openBlock(), _createElementBlock("td", _hoisted_119))
                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                      _createElementVNode("td", _hoisted_120, [
                                                        _createElementVNode("span", {
                                                          innerHTML: licenseItem[0].Product.Name
                                                        }, null, 8, _hoisted_121),
                                                        (_ctx.GetCount(licenseItem)>0)
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_122, " (" + _toDisplayString(_ctx.GetCount(licenseItem)) + ") ", 1))
                                                          : _createCommentVNode("", true)
                                                      ]),
                                                      _createElementVNode("td", _hoisted_123, _toDisplayString(licenseItem[0].FormattedAddedDate), 1),
                                                      _createElementVNode("td", _hoisted_124, [
                                                        (licenseItem[0].IsRenewable == null)
                                                          ? (_openBlock(), _createElementBlock("span", _hoisted_125, _toDisplayString(_ctx.$t('No')), 1))
                                                          : (_openBlock(), _createElementBlock("span", _hoisted_126, [
                                                              (licenseItem[0].IsRenewable)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_127, _toDisplayString(_ctx.$t('Yes')), 1))
                                                                : (_openBlock(), _createElementBlock("span", _hoisted_128, _toDisplayString(_ctx.$t('No')), 1))
                                                            ]))
                                                      ])
                                                    ], 64))
                                              ]))
                                            }), 128))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : (_openBlock(), _createElementBlock("tbody", _hoisted_129, [
                                        _createElementVNode("tr", null, [
                                          _createElementVNode("td", null, _toDisplayString(_ctx.$t('NoActiveAddOns')), 1)
                                        ])
                                      ]))
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_130, [
                                _createElementVNode("table", _hoisted_131, [
                                  _createElementVNode("tr", _hoisted_132, [
                                    _createElementVNode("td", _hoisted_133, _toDisplayString(_ctx.$t('PremiumFeatures')), 1)
                                  ]),
                                  (_ctx.applicationPremiumFeaturesReadOnly!=null && _ctx.applicationPremiumFeaturesReadOnly.length>0)
                                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_134, [
                                        _createElementVNode("tr", _hoisted_135, [
                                          _createElementVNode("th", _hoisted_136, _toDisplayString(_ctx.$t('Name')), 1),
                                          _createElementVNode("th", _hoisted_137, _toDisplayString(_ctx.$t('AddedDate')), 1),
                                          _createElementVNode("th", _hoisted_138, _toDisplayString(_ctx.$t('Renewable')), 1)
                                        ]),
                                        (_ctx.GetPremiumFeaturesCount())
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.groupedFeaturesList, (licenseItem) => {
                                              return (_openBlock(), _createElementBlock("tr", {
                                                key: licenseItem.Id
                                              }, [
                                                _createElementVNode("td", _hoisted_139, [
                                                  _createElementVNode("span", {
                                                    innerHTML: licenseItem[0].Product.Name
                                                  }, null, 8, _hoisted_140)
                                                ]),
                                                _createElementVNode("td", _hoisted_141, _toDisplayString(licenseItem[0].FormattedAddedDate), 1),
                                                _createElementVNode("td", _hoisted_142, [
                                                  (licenseItem[0].IsRenewable == null)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_143, _toDisplayString(_ctx.$t('No')), 1))
                                                    : (_openBlock(), _createElementBlock("span", _hoisted_144, [
                                                        (licenseItem[0].IsRenewable)
                                                          ? (_openBlock(), _createElementBlock("span", _hoisted_145, _toDisplayString(_ctx.$t('Yes')), 1))
                                                          : (_openBlock(), _createElementBlock("span", _hoisted_146, _toDisplayString(_ctx.$t('No')), 1))
                                                      ]))
                                                ])
                                              ]))
                                            }), 128))
                                          : _createCommentVNode("", true)
                                      ]))
                                    : (_openBlock(), _createElementBlock("tbody", _hoisted_147, [
                                        _createElementVNode("tr", null, [
                                          _createElementVNode("td", null, _toDisplayString(_ctx.$t('NoActivePremiumFeatures')), 1)
                                        ])
                                      ]))
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_148, [
                        _createElementVNode("div", _hoisted_149, [
                          _createElementVNode("div", _hoisted_150, [
                            _createElementVNode("label", _hoisted_151, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.ConsumptionMonth")), 1),
                            _withDirectives(_createElementVNode("select", {
                              id: "selMonthtype",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedMonth) = $event)),
                              class: "form-control month-type-width selectBox",
                              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.GetBillingReportDetails()))
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MonthNameDataList, (monthtype) => {
                                return (_openBlock(), _createElementBlock("option", {
                                  key: monthtype.Month_Name,
                                  value: monthtype.ID
                                }, _toDisplayString(_ctx.$t(monthtype.Month_Name)), 9, _hoisted_152))
                              }), 128))
                            ], 544), [
                              [_vModelSelect, _ctx.selectedMonth]
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_153, [
                            (_ctx.isReportLoading)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_154, [
                                  _createElementVNode("img", {
                                    class: "img-responsive ajax-loader-img",
                                    src: _ctx.loadingImage,
                                    alt: "Wait",
                                    style: {"width":"50px","margin-top":"20px"}
                                  }, null, 8, _hoisted_155)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        (_ctx.listConsumptionBasedProduct.length == 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_156, [
                              _createElementVNode("div", _hoisted_157, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.NoActivity")), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.listConsumptionBasedProduct.length > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_158, [
                              _createElementVNode("table", _hoisted_159, [
                                _createElementVNode("tbody", null, [
                                  _createElementVNode("tr", _hoisted_160, [
                                    _createElementVNode("th", _hoisted_161, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.Date")), 1),
                                    _createElementVNode("th", _hoisted_162, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.Product")), 1),
                                    _createElementVNode("th", _hoisted_163, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.ConsumptionMinutes")), 1),
                                    _createElementVNode("th", _hoisted_164, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.Amount")), 1)
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listConsumptionBasedProduct, (item, index) => {
                                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                      _createElementVNode("td", _hoisted_165, _toDisplayString(_ctx.formatTime(item.UpdatedAt)), 1),
                                      _createElementVNode("td", _hoisted_166, _toDisplayString(item.ProductName), 1),
                                      _createElementVNode("td", _hoisted_167, _toDisplayString(item.TotalMinutes) + " " + _toDisplayString(_ctx.$t("ConsumptionBasedProducts.Minutes")), 1),
                                      _createElementVNode("td", _hoisted_168, _toDisplayString(item.Billing), 1)
                                    ]))
                                  }), 128)),
                                  _createElementVNode("tr", _hoisted_169, [
                                    _createElementVNode("td", _hoisted_170, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.TotalBilling")), 1),
                                    _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
                                    _cache[11] || (_cache[11] = _createElementVNode("td", null, null, -1)),
                                    _createElementVNode("td", _hoisted_171, _toDisplayString(_ctx.$t("ConsumptionBasedProducts.DetailTable.USD", {totalbill: _ctx.GetTotalBill()})), 1)
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_172, [
                      (_ctx.isLoggedInUserSuperAdmin)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            id: "btnEditLicense",
                            class: "btn btn-secondary hidden-xs hidden-sm",
                            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.EditLicense && _ctx.EditLicense(...args)))
                          }, _toDisplayString(_ctx.$t('Editlicense')), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-primary",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => {_ctx.Cancel();})
                      }, _toDisplayString(_ctx.$t("Button.Close")), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}