import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "modal" }
const _hoisted_4 = { class: "modal-dialog" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.localBorderColor,
      "loader-opacity": 1
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localShowModalPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "close",
                        "data-dismiss": "modal",
                        "aria-label": "Close",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("span", { "aria-hidden": "true" }, "×", -1)
                      ])),
                      _createElementVNode("h4", {
                        class: "modal-title",
                        textContent: _toDisplayString(_ctx.newsTitle)
                      }, null, 8, _hoisted_7)
                    ]),
                    _createElementVNode("img", {
                      src: _ctx.newsImgUrl,
                      class: "imgstyle"
                    }, null, 8, _hoisted_8),
                    _createElementVNode("div", {
                      class: "modal-body",
                      innerHTML: _ctx.newsContent
                    }, null, 8, _hoisted_9),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-primary btn-size-default",
                        "data-dismiss": "modal",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
                      }, _toDisplayString(_ctx.$t('Button.Close')), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}