import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showLoading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "loading-wrapper",
        style: _normalizeStyle(_ctx.GetOpacity())
      }, [
        _createElementVNode("img", {
          class: "loading-img",
          src: _ctx.loadingImage
        }, null, 8, _hoisted_1)
      ], 4))
    : _createCommentVNode("", true)
}