import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "trial-block hidden-xs" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "modalpopup" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "modal" }
const _hoisted_6 = { class: "modal-dialog" }
const _hoisted_7 = { class: "modal-content" }
const _hoisted_8 = { class: "modal-header" }
const _hoisted_9 = { class: "modal-title" }
const _hoisted_10 = { class: "modal-body new-trial-popup-body" }
const _hoisted_11 = ["hidden"]
const _hoisted_12 = { class: "new-trial-max-width" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-lg-12 new-trial-container-div" }
const _hoisted_15 = { class: "new-trial-line1" }
const _hoisted_16 = { class: "new-trial-line2" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "discuss-more-button-container" }
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        innerHTML: _ctx.$t('TrialIndicator.Message',{pendingDays: _ctx.packageExpirationPendingDays})
      }, null, 8, _hoisted_2),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.OpenPopup()))
      }, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t("TrialIndicator.Button")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.localShowModalPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, [
                      _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("TrialIndicator.UpgradeHeader")), 1)
                    ]),
                    _createElementVNode("a", {
                      id: "closeUpgradePopup",
                      href: "javascript:void(0)",
                      "aria-hidden": "true",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", {
                        class: "fa fa-times close-icon",
                        "aria-hidden": "true"
                      }, null, -1)
                    ]))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", {
                        id: "dvSuccessPackageUpgradeRequest",
                        class: "alert alert-success",
                        hidden: _ctx.successMessage==''
                      }, _toDisplayString(_ctx.successMessage), 9, _hoisted_11),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("TrialIndicator.NewTrialMessageLine1")), 1),
                            _createElementVNode("p", _hoisted_16, [
                              _createTextVNode(_toDisplayString(_ctx.$t("TrialIndicator.NewTrialMessageLine2")) + " ", 1),
                              _createElementVNode("a", {
                                target: "_blank",
                                href: _ctx.pricingUrl,
                                class: "open-pricing-anchor"
                              }, _toDisplayString(_ctx.$t("TrialIndicator.NewTrialMessageLine3")), 9, _hoisted_17)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("button", {
                            id: "btnNewTrialPackgageUpgradeRequest",
                            disabled: _ctx.isContactButtonDisabled,
                            class: "btn discuss-more-button",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SubmitNewTrialUpgradePackageRequest()))
                          }, _toDisplayString(_ctx.$t("TrialIndicator.NewTrialMessageLine4")), 9, _hoisted_19)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}